<template>
  <h1>Standorte</h1>
  <p>
    Von unserem Büro in Schlehdorf am Kochelsee in Oberbayern organisieren und
    steuern wir unsere Einsätze bei Ihnen vor Ort.
  </p>
  <div class="Image">
    <img src="../assets/standorte/standorte.jpg" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Standorte',
  components: {},
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.Image {
  text-align: center;
}
</style>
